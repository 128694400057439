html {
  background-color: #282c34;
  color: white;
}

.App {
  text-align: center;
}

header {
  margin-top: 2em;
  margin-bottom: 2em;
}
header img {
  max-height: 600px;
  max-width: 80vw;
}
form, input {
  font-size: 1.5rem;
}
label {
  display: inline-block;
}
.error {
  color: deeppink;
  font-size: 2rem;
  margin-top: 1rem;
}
.blink {
  animation: blinker 1s infinite;
}

@keyframes blinker {
  from { opacity: 1.0; }
  50% { opacity: 0.5; }
  to { opacity: 1.0; }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
